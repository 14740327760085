import React from 'react'
import { Link } from "gatsby"



export default class Header extends React.Component {

render() {
  return (<header className="u-header" id="header" >
  </header>);
  }
}
