import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Footer from './Footer'
import Header from './Header'
import '../../stylesheets/main.scss'

export default props => (
  <StaticQuery
    query={graphql`
      {
        prismic {

          allSite_meta_datas(uid: null) {
            edges {
              node {
                _meta {
                  id
                  uid
                  type
                }
                phone
                phone_display_text
                email
                admin_email
                city
                body {
                  __typename
                  ... on PRISMIC_Site_meta_dataBodyMenu {
                    fields {
                      item_name
                      relative_link
                    }
                  }
                }
                default_keywords
                logo
                copyright
                copyright_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                facebook_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                instagram_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                linkedin_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                rate_my_agent_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                youtube_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                rea_link {
                  __typename
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            siteURL
          }
        }
      }

    `}
    render={ data => <Layout data={ data } { ...props }/> }
  />
)

const Layout = ( props ) => {

  // Load the Prismic edit button
  if(typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton()
  }

  const siteMetadatas = props.data.prismic.allSite_meta_datas.edges.slice(0,1).pop().node;

	return(
    <Fragment>
      <Header siteMetadatas={siteMetadatas}/>
        { props.children }
      <Footer siteMetadatas={siteMetadatas}/>
    </Fragment>
	)
}
