import React from 'react'

export default (props) =>
<footer className="container space-1">
  <div className="row justify-content-sm-between">
    <div className="col-sm-6 mb-4 mb-sm-0">
      <p className="small text-muted mb-0">
        &copy; 2019, powered by <a href={props.siteMetadatas.copyright_link.url} target="_blank" rel="noopener noreferrer">{props.siteMetadatas.copyright}</a>
      </p>
    </div>
  </div>
</footer>
